import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/about-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Comunidades a las que Pertenezco`}</h1>
    <p>{`Tengo la gran oportunidad de formar parte de varias comunidades en la ciudad de Panamá. Las comunidades más importantes son:`}</p>
    <h2>{`Comunidad de WordPress de la Ciudad de Panamá`}</h2>
    <div {...{
      "className": "small-image float-right small-border transparent-background"
    }}>
      <span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "678px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFCklEQVQ4y21UC0yTVxS+LeAjzrjNLctiNrcEXPYIi84YJNFN3RxuKkOnS2TORcOGMrIJooAQxUhgQwqNj4JVYBQQrLxqbWl59cVLoYVii+CsWJA3K9PhSv/+91v+BhASv+TkP//Jvd/9zrn3HAKAcKbVavkmk8mb85sbGz0xN0DEew9y/uJnq/fycEpMZtbPtXngAizLEp1OR6xWK/e/yNRmPARgOQCfRklJnOG8uOcvYBm3Nlec6V2YL/JqN93hnzh2mOzdtWU+qdPpJAMDA3yLxRIwODgYbDIaNQ/77Gi5Vtqh2RNub161BfX+Qeh+MrZWplKSmNhwIs7OJNZ7XaS8pMCLI3v2dOK52mlnUVZWVn9paSlYN4sex7/M4/ommHl+MJN3pozrQtBaq72sv90Upq1TSvW6msK+/v4va6oUnv11ajl/llAqlfKrqqpIh9m8JTo6GmZzByuQm+h7kflueZqEYaoNcNy20FvyUuSKknEtT4Ci3HSo5UWT9ge2rwEslJmGiB8hPPvoJM/DGhIS4pUQG0Oq1aqjUceO45U96S6yMQUBiddhs9tYaUYyCq9msHmiDKbwSgZTlCN0/ZktgOxGDsrKSh519DzK/oQQMjz5PGWunLxlhBC5tECyekcYPj5yZTjtfI4t7UwUiiVCtjw/Dh2qfVRf/Dm1yNeiRepPE37e6a4QxMCQnwSZ6FSbzT4UQBiGmSHlTX99EpKS+84JRSoA63XVN3E+NRwVGYvo3Rt89Kv5dMJAKCwETeKFKAt706X/zReVgsgxuwu+npt2OBzk77FRMmy3eQNujnSlSJg+1tLedU58MXdAnPgW9HmErcz0grFkAUY1fDAmH4oewqrz/VCuuCPkaqlp63/+FucYv6SmMXXb8UwmMHg7zgrToNfUUEPFfowYg9FVF4zx3hI8fqCi472lzEB3IXpt3b+3mDuJVqv0mSX8IVHAj0q9QJTaxpRtiSJ8cKyAVl3/wm2s2EAf2ofR0NBC61TlaJZ9i4mnAMMCbsD9xAWY201187qlr69/pn6vi2U145FX5fjxQiV7WaGigb8m0JLaZvwzPkAt5jYYW+pham1Gl7WTjnTfdXcpK2Fpa9XMCPOgulbDb6pRk4Ya9YcXK+sRI1GxmfJGGpSURyOyboID45rC1JQTk8+cYN0MGJahBpHEbRAVYsLhqM4+mUQWzyjs7X00o/CNFIlsIraoGqfzb7p/Cg+nn27aRONjj6NKqaCFhQU4cOAAamvr6C8RhxG4ebcrIeUS7nV3ZdXW15IKudJrtobEdxuf+O8kRQrthe1ncrDiYCrdELSDBq5bw+VBCSHu15a/yn6/fz+Ctm6m0zH2sw3rMTIyurW/r58oFQqvF96ysFhxaWNcNrsm9Ci+2rHb/a7vKnjzCZa+tAQR4WF0e1Agd4hrjf/7KK+oFE8nyputIaWUwDk5+7gJeZnzV3Tet0de++OIW3pVgKy867rQ0FBrRHQ8oqKip9J2LIZCdOIBgAVzG2MW7ZZuArAelYfizvrc67SQW/XGXT1JK+GIJ2gtTZcZm3QXGyRJ/1mT/GAUfuO0DroCHo5MkZ72Js8YGx4enj9sPUq50yjDmy7FEk1xptya/BF6Ty7F/dMrYIx/G7rsyK6GIayr0xhIWY7AS5RwkHAT64XgCMdHh4jNAd74dG/r23v3qQsEl2olqZf1d6zfcWnKzE6SnZHCXz1nH4f/AZeKvtPm/Q18AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Wappu Montuno",
          "title": "Wappu Montuno",
          "src": "/static/e203b15d9a1f1d594a00d367ee8c6508/38cea/wappu-panama.png",
          "srcSet": ["/static/e203b15d9a1f1d594a00d367ee8c6508/1d8ec/wappu-panama.png 171w", "/static/e203b15d9a1f1d594a00d367ee8c6508/41431/wappu-panama.png 343w", "/static/e203b15d9a1f1d594a00d367ee8c6508/38cea/wappu-panama.png 678w"],
          "sizes": "(max-width: 678px) 100vw, 678px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>
    </div>
    <p>{`La comunidad de WordPress de la Ciudad de Panamá fue fundada y es liderada por `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/ernestomorales/"
      }}>{`Ernesto Morales`}</a>{` en el año 2018 para reunir a los usuarios del sistema y compartir conocimientos. El primer meetup se dio en las oficinas de `}<a parentName="p" {...{
        "href": "https://globalinternet.com.pa/"
      }}>{`Global Internet`}</a>{` el 20 de Octubre de 2018 y fui la primera persona en asistir, además de los anfitriones Ernesto Morales y `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/neztorweb/"
      }}>{`Neztor Aconcha`}</a>{`. Desde ese entonces he formado parte de la comunidad como organizador de eventos tan importantes como el primer WordCamp de la Ciudad de Panamá en el año 2019 hasta organizar encuentros y dar charlas a los miembros de la comunidad. La comunidad de WordPress ha crecido desde tres miembros hasta más de 600 al día de hoy. Es una de las comunidades más importantes de la Ciudad y sigue creciendo.`}</p>
    <h2>{`Style Leading`}</h2>
    <div {...{
      "className": "small-image float-right small-border transparent-background"
    }}>{`
  `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "634px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "55.55555555555556%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB700yiq//xAAWEAEBAQAAAAAAAAAAAAAAAAARACD/2gAIAQEAAQUCZnH/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8BhX//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAYEAACAwAAAAAAAAAAAAAAAAAAAREgcf/aAAgBAQABPyEZYpU//9oADAMBAAIAAwAAABB/3//EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxChf//EABgRAQEAAwAAAAAAAAAAAAAAAAEAESFR/9oACAECAQE/EHpA423/xAAbEAEAAgIDAAAAAAAAAAAAAAABABFRkRAxQf/aAAgBAQABPxBPg6jhqgBQcdcgBQVP/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Style Leading",
          "title": "Style Leading",
          "src": "/static/c1b844de18e6af74d9dc9d63cd094675/f2afa/logo-style-leading.jpg",
          "srcSet": ["/static/c1b844de18e6af74d9dc9d63cd094675/0b054/logo-style-leading.jpg 171w", "/static/c1b844de18e6af74d9dc9d63cd094675/ebfc4/logo-style-leading.jpg 343w", "/static/c1b844de18e6af74d9dc9d63cd094675/f2afa/logo-style-leading.jpg 634w"],
          "sizes": "(max-width: 634px) 100vw, 634px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>
    </div>
    <p>{`La Comunidad de StyleLeading es una comunidad nueva fundada por `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/jairpoveda/"
      }}>{`Jair Manuel Poveda`}</a>{` fundador de la agencia de desarrollo web `}<a parentName="p" {...{
        "href": "https://cooltimedia.com/"
      }}>{`Cooltimedia`}</a>{`. Esta comunidad fue fundada a principios del año 2020 y se dedica a fomentar el uso y la práctica de las tecnologías fundamentales de la web, como HTML, CSS y JavaScript vainilla. Participo muy activamente en esta comunidad ya que creo mucho en el trabajo de esta y espero ser parte importante de la misma inspirando a las personas a adoptar estas tecnologías y utilizarlas en su ámbito profesional.`}</p>
    <h2>{`React Panamá`}</h2>
    <div {...{
      "className": "small-image float-right small-border transparent-background"
    }}>
      <span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "486px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "89.47368421052632%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsSAAALEgHS3X78AAAE70lEQVQ4y1WUW0wUVxzG/y7Wpk3ah0YeNPWhtU21tbxYa2rSJrZVsRG5FTReCgFhKxe1VUGqYhQtReUqeOMqRtFAFe9KLVhNiSAVFBBY9jKzs8Au67I7s8vO7OzOfs1ga9N5Ojnn5De/7+Sfj4iIABDLshqz2UyCIERaLBaB4zjJZrMVAHjTC5CUtFK9977n6O5G9940eAp29QVF7/wAayC54w+Nynj5ud3uaeoGgDkmkwlGo7GD47gzBoMBjNEwoQCL5cbaBH5zLHhttNtzaHsFn7jS6c5Jffyv0P+AHMeFMAxD4+Pj3xmNRgB4Vz84QBDFMNOotVvPsHBqY+DJTW8AEOpcNo/Ei9UbhIx4BH3Se4pjnOT2Vs0UbO42HzEmU8iwboisNtv2Yb1h0qVgtvpHoaaMcLkuw1KYC+NAvwzgE1G1cXST7XTFV67sFEzebg7z3bhIUlOtZl+T/4VuW49rBjBCgtMaMT5iACB+FPrlQ1LGuEj7hnC4TpX26OyCVTc4bAH8Hy+imYSzPyYFM1eraUJVRk+XZTqtkYhooXfayd/V/BwNcGJS/V0rMmu8zzbWwLI2l0Xc1ieIKEcg6oiI6MM84opEX0ItLDkF/bamrcW8GVhOr4DuDygUXSRr6LUEiR4MKqu0VbIhutiPxLIJ7L4GW2PhDWtHQkrw3hgSLvVhSaMNix4OTubd/tOI+g4825/eKCQfMCLmGJBZ6+tm7cHPss77iervBzJji2Wk1chPm//C0knXaA0/MighaamMsux89Z2l3vaDaCjVqmv7OPfAqevmsXGxHy11qWd1iEg6IXFry2R0GpQVtPG4rOw4568islKXDeQGwgYfPYLtUPYogJAhm9DUf+cWHDFL4LlQk+oBFgx1dsD6y249gFdbx6ZGZpq2Su7UVsl6Wl8uo6Il8MO87CABvST16ueyZ05Cx3BDREco75Lv3jd5z9HfehcOkz4HLDPbXFOOYdby9L/5e06Zdf7WlEqZo4OX/ZWRhTKONIvHAbyB41vy/dpwsKMWSK7RjOouhB69Ll5lrc/Vc/Lv2dQobouD3TmqAGOR7cNYmFYjP/62RMZvvUrclO6xO4H8taUS1lQhuH/nLflywSVjFyvqn/QZPG4ZCwH3bCcw0/zoWQKzLgIdN7t7j94UnOlVHl9siR+bTssj7Tpl1bKf5RdzuCB3Sn1W9TVHUX7GBSSW8EJEkSKtLhAQV+xFXGlAij8FOT5Lh4hsFrEVcKdXuaWCX+1C2wA2qlLVbQGieEkzBTxx4NaMYHMd4fxPS5G2AvDov2CDeHvI7N15v3MY7SblydM9eTZdSqJoDiIKwOvwjWxxWg2KWh4qo+WJb/qL9/zUS8LWdSHu8PkkVJZsdySv9gaAWTxArQaZvMB+U99juHes96GtYeVY03VyqO00Yv/axJhhd7jCeNcEORwOTVyp/E/bZCeH8KlR5K0sTOFTVQHMluuKVfu3JrOSG8cOZWFo1Aqrw7lXjTfKsSogimEYSJL0gSiKxPO85mXb+FquaAIWhhTe+SH/fQzcezY3eOvLd/LaaJ7XRgNXz623OV0HDcPDYBimx+l0bjaZTH2MSgRmqFEVRZn2Eqhu2OeQZuLzd0i6cn6XkLEGQno8PIdz7gGYp+xLp047T57JyXCLxcKqFceyrN/r9S7neX4qrs/nm4L9Da5vxnSZlXscAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "React Panama Logo",
          "title": "React Panama Logo",
          "src": "/static/267d48366ffa78669e1e74fccd5a1014/4ee7f/react-panama-logo.png",
          "srcSet": ["/static/267d48366ffa78669e1e74fccd5a1014/1d8ec/react-panama-logo.png 171w", "/static/267d48366ffa78669e1e74fccd5a1014/41431/react-panama-logo.png 343w", "/static/267d48366ffa78669e1e74fccd5a1014/4ee7f/react-panama-logo.png 486w"],
          "sizes": "(max-width: 486px) 100vw, 486px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>
    </div>
    <p>{`La comunidad de React Panamá es una comunidad muy joven, fundada por mi persona y Ernesto Morales. La misión de esta comunidad es compartir conocimientos de esta tecnología que ha crecido y es de gran importancia para la creación de interfaces de usuario, al igual que conocernos y compartir ideas. Empezó en Marzo de 2020 y como una comunidad pequeña pero ha crecido con mucha aceptación. Nuestra meta es formar parte de la comunidad de React internacional empezando con la comunidad de Colombia, y promocionar a la ciudad como un centro de interés y conocimiento por la tecnología.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      